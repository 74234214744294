import { useEffect, useState } from 'react'
import { TPieChartData } from 'services/chart.interface'
import { axios } from 'utils'
import { v4 as uuid } from 'uuid'
import { useQuery } from 'react-query'
import { newTFocusGroup, TCostHistoryQueryResponse, TFocusGroup } from 'services/costhistory.interface'
import { ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts'
import useAuthentication from 'hooks/useAuthentication'
// import LabeledDataComponent from 'components/LabeledDataRepresenation'
import { LineChart } from '@mantine/charts'
import { useElementSize } from '@mantine/hooks'
import { SpinnerLoader } from 'components'
import { dateFormatChanger, parseAccumulatedTrueData, renderLabel, renderLegend } from './chartHelperFunctions'
import { createWidgetAggregatedAPIFieldCall, createWidgetAPIFieldCall, dataNameAndColor, projectAndTheirApps, query } from '../project-widget/widgetHelpFunctions'
import { WidgetData } from '../project-widget/widgetStatev2'
import { getWidgetData, setWidgetData } from '../project-widget/widgetDataCaching'

interface PredefinedGroupByProps {
  id: string,
  tag: string,
  widgetObject: WidgetData,
  deleteWidget: () => void,
}
export function PredefinedGroupBy({ tag, id, widgetObject, deleteWidget }: PredefinedGroupByProps) {
  widgetObject.resetOptions(id)
  const { currency } = useAuthentication()
  const [dataProcessed, setDataProcessed] = useState<boolean>(getWidgetData(`PredefinedWidget${id}${tag}`).length !== 0)
  const [focusData, setFocusData] = useState<TFocusGroup[] | newTFocusGroup[]>(
    getWidgetData(`PredefinedWidget${id}${tag}`)
  )
  const [chartDataValues, setChartDataValues] = useState<projectAndTheirApps[]>([])
  const [chartDataNames, setChartDataNames] = useState<dataNameAndColor[]>([])
  const [accumulatedChartData, setAccumulatedChartData] = useState<TPieChartData[]>([])
  const [queryKey, setQueryKey] = useState<string>(uuid())
  const [query] = useState<query>(
    tag === 'accumulateTrue' ?
      createWidgetAggregatedAPIFieldCall('serviceName', '') : createWidgetAPIFieldCall('serviceName', 'lastmonth', '1d', '')
  )

  widgetObject.editOptions(id, 'Delete widget', () => {
    sessionStorage.removeItem(`PredefinedWidget${id}${tag}`)
    deleteWidget()
  })
  const {
    ref,
    // width,
    // height
  } = useElementSize();

  const { error } = useQuery({
    queryKey: [`query?${queryKey}`],
    queryFn: async () => {
      try {
        if (!dataProcessed) {
          setDataProcessed(true)
          await axios
            .post<TCostHistoryQueryResponse>('/query', query)
            .then((res) => {
              setFocusData(res.data.data)
              setWidgetData(`PredefinedWidget${id}${tag}`, `PredefinedWidget${id}${tag}`, res.data.data)
            }).catch((error) => {
              if (error.response.data.data) {
                setDataProcessed(true)
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })
  useEffect(() => {
    setQueryKey(uuid())
  }, [query])

  useEffect(() => {
    if (tag === 'accumulateFalse') {
      const dataToWorkWith = focusData as TFocusGroup[]
      const newData: projectAndTheirApps[] = []
      dataToWorkWith.forEach((el) => {
        const label = Object.getOwnPropertyNames(el)
        const { timestamp, totalEffectiveCost } = el[label[0]]
        const newTimestamp = dateFormatChanger(timestamp.toString())
        const index = newData.findIndex((el) => el.label === newTimestamp)
        if (index !== -1) {
          newData[index].Spend += totalEffectiveCost
        } else {
          newData.push({ label: newTimestamp, Spend: totalEffectiveCost })
        }
      })
      setChartDataValues(newData)
      setChartDataNames([{ name: 'Spend', color: 'indigo.6' }])
      // const res = parseAccumulatedFalseData(focusData as TFocusGroup[])
      // setChartDataValues(res.values)
      // setChartDataNames(res.names)
    } else {
      const res = parseAccumulatedTrueData(focusData as newTFocusGroup[], [])
      setAccumulatedChartData(res.sort((a, b) => b.value - a.value))
    }
  }, [focusData])

  if (error) return <div>An error has occurred</div>
  return (
    <div className="h-full" ref={ref}>
      <SpinnerLoader isLoading={!dataProcessed}>
        {tag === 'accumulateTrue' && accumulatedChartData.length !== 0 && (
          <div className="h-full">
            <div className="font-bold text-gray-600">
              Total spend by product:
            </div>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={accumulatedChartData}
                  dataKey="value"
                  nameKey="name"
                  innerRadius={0}
                  labelLine={false}
                  cx="50%"
                  cy="50%"
                  fill="#82ca9d"
                  label={(value) => {
                    return renderLabel(value, currency, accumulatedChartData[0].value)
                  }}
                >
                  {accumulatedChartData.map((entry) => (
                    <Cell key={entry.color} fill={entry.color} />
                  ))}
                </Pie>
                <Legend content={renderLegend(accumulatedChartData, id, currency)} height={36} layout="vertical" align="left" verticalAlign="top" />
              </PieChart>
            </ResponsiveContainer>
          </div>
        )}
        {tag === 'accumulateFalse' && chartDataValues.length !== 0 && (
          <div style={{ display: 'block', height: '75%' }}>
            <div className="font-bold text-gray-600">
              Spend for previous Month and trends:
            </div>
            <div className="h-full">
              <LineChart
                h={300}
                data={chartDataValues}
                dataKey="label"
                series={chartDataNames}
                curveType="linear"
                valueFormatter={(value) => value.toFixed(2)}
                unit={currency.symbol}
              />
            </div>
          </div>

        )}
      </SpinnerLoader>
    </div>
  )
}
