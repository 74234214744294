import { useEffect, useState } from 'react'
import { toast, Bounce } from 'react-toastify'
import { axios } from 'utils'
import { useQuery } from 'react-query'
import { LRComponent } from './LRComponent'

interface LabelRuleCreatorProps {
  labelRuleList: { columnName: string, value: string }[],
  setLabelRuleList: (lst: { columnName: string, value: string }[]) => void,
  labelEffectList: { effectName: string, value: string }[]
  setLabelEffectList: (lst: { effectName: string, value: string }[]) => void,
}
export function LabelRuleCreator({
  labelRuleList,
  setLabelRuleList,
  labelEffectList,
  setLabelEffectList
}: LabelRuleCreatorProps) {
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [sendLabelRule, setSendLabelRule] = useState<boolean>(false)

  useQuery({
    queryKey: [sendLabelRule],
    queryFn: async () => {
      try {
        if (sendLabelRule) {
          await axios
            .post('/label-rules', {
              name,
              description,
              enabled: true,
              rules:
                labelRuleList.map((el) => {
                  return {
                    key: el.columnName,
                    operand: 'EQUALS',
                    value: el.value,
                  }
                }),
              effects: labelEffectList.map((el) => {
                return {
                  key: el.effectName,
                  value: el.value,
                  children: [],
                }
              }),
            })
            .then(() => {
              setSendLabelRule(false)
              setName('')
              setLabelRuleList([])
              setLabelEffectList([])
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('Creation of the label-rule was not possible.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })
  useEffect(() => {
    let changed = false
    let ctr = 1
    console.log('test')
    const newList = labelEffectList.map((effect) => {
      if (effect.effectName !== `${name}Effect${ctr}`) {
        effect.effectName = `${name}Effect${ctr}`
        changed = true
      }
      ctr++
      return effect
    })
    if (changed) setLabelEffectList(newList)
  }, [labelEffectList, name])

  return (
    <div className="flex flex-col">
      <div className="flex flex-row w-full border border-gray-500 rounded-lg bg-white shadow-2xl my-3">
        <span className="w-2/5 border-r border-gray-500 rounded-l-lg p-1 bg-gray-300 w-20">name:</span>
        <input
          className="h-max w-full p-1 rounded-r-lg"
          placeholder="Type a name for the label rule."
          type="text"
          id="myText"
          value={name}
          onChange={(event) => {
            setName(event.target.value)
          }}
        />
      </div>
      <div className="flex flex-row w-full border border-gray-500 rounded-lg bg-white shadow-2xl my-3">
        <span className="w-2/5 border-r border-gray-500 rounded-l-lg p-1 bg-gray-300 w-20">description:</span>
        <input
          className="h-max w-full p-1 rounded-r-lg"
          placeholder="Type a description for the label rule."
          type="text"
          id="myText"
          value={description}
          onChange={(event) => {
            setDescription(event.target.value)
          }}
        />
      </div>
      <div>
        <div className="flex flex-col w-full border border-gray-500 rounded-lg bg-white shadow-2xl my-3">
          <span className="w-full border-b border-gray-500 rounded-t-lg p-1 bg-gray-300 w-20">Label rules:</span>
          <div className="p-2">
            {labelRuleList.map((el) => {
              return (
                <div key={`${el.columnName}${el.value}`}>
                  <LRComponent
                    columnName={el.columnName}
                    value={el.value}
                    deleteCallback={() => {
                      setLabelRuleList(labelRuleList.filter((rule) => {
                        return !(rule.columnName === el.columnName && rule.value === el.value)
                      }))
                    }}
                  />
                </div>
              )
            })}
          </div>
        </div>
        <div className="flex flex-col w-full border border-gray-500 rounded-lg bg-white shadow-2xl my-3">
          <span className="w-full border-b border-gray-500 rounded-t-lg p-1 bg-gray-300 w-20">Label rule effects:</span>
          <div className="p-2">
            {labelEffectList.map((el) => {
              return (
                <div key={`${el.effectName}${el.value}`}>
                  <LRComponent
                    columnName={el.effectName}
                    value={el.value}
                    deleteCallback={() => {
                      const temp = labelEffectList.filter((rule) => {
                        return !(rule.effectName === el.effectName && rule.value === el.value)
                      })
                      let ctr = 1
                      setLabelEffectList(temp.map((effect) => {
                        effect.effectName = `${name}Effect${ctr}`
                        ctr++
                        return effect
                      }))
                    }}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <button
          className="bg-white hover:bg-gray-600 text-black hover:text-white border-2 rounded-lg border-black w-fit p-2 m-1"
          type="button"
          onClick={() => {
            setLabelRuleList([])
            setLabelEffectList([])
          }}
        >
          Cancel
        </button>
        <button
          className="bg-[#668957] hover:bg-[#93ac89] text-white border-2 rounded-lg border-[#668957] w-fit p-2 m-1"
          type="button"
          onClick={() => {
            if (name !== '' && !/[A-Z]/.test(name) && !/[!@#$%^&*()\-+={}[\]:;"'<>,.?/|\\]/.test(name)) {
              if (labelEffectList.length !== 0 && labelRuleList.length !== 0) {
                setSendLabelRule(true)
              } else {
                toast.error('Define at least one label rule and one effect.', {
                  // position: 'top-right',
                  className: 'relative',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            } else {
              toast.error('Name has to be defined. Capital letters and special chars not allowed', {
                // position: 'top-right',
                className: 'relative',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
                transition: Bounce,
              })
            }
          }}
        >

          Create label rule
        </button>
      </div>
    </div>
  )
}
