import { effectData } from 'pages/label-rules/mutation-new-label-rule-model/hierarchyHelperFunctions';
import { TReportOperator } from 'services/report.interface';
import { query } from '../project-widget/widgetHelpFunctions'

export type TagComponent = { tag: string, selected: boolean, parentConditionMet: boolean }

// ***************************************************************************
// Make sure we have no duplicates in the tags that can be chosen from when
// editing the widget.
// ***************************************************************************
export function noDupsLabelList(labelRuleDetailsList: effectData[]): string[] {
  const temp = labelRuleDetailsList.map((el) => { return el.name })
  return temp.filter((element, index) => {
    return temp.indexOf(element) === index;
  });
}

export function makeNewQueryRequest(tagSelector: string, aggregateString: string[]): query {
  let filter: {
    field?: string | undefined;
    value?: string | number | undefined;
    operator?: TReportOperator | undefined
  }[] = []
  if (tagSelector === 'labels' && aggregateString.length !== 0) {
    const firstParent = aggregateString[0]
    filter = [{
      field: `label.${firstParent}`,
      value: '',
      operator: 'CONTAINS'
    }]
  }
  let newString = ''
  if (tagSelector === 'labels' && aggregateString.length !== 0) {
    const parts = aggregateString
    let res = ''
    parts.forEach((el) => {
      if (res === '') {
        res = `label.${el}`
      } else {
        res = `${res}, label.${el}`
      }
    })
    newString = res
  } else {
    const parts = aggregateString
    let res = ''
    parts.forEach((el) => {
      const words = el.split(' ')
      let resWord = ''
      words.forEach((word) => {
        if (resWord === '') {
          const newWord = word.charAt(0).toLowerCase() + word.slice(1)
          resWord = newWord
        } else {
          const newWord = word.charAt(0).toUpperCase() + word.slice(1)
          resWord = `${resWord}${newWord}`
        }
      })
      if (res === '') {
        res = resWord
      } else {
        res = `${res}, ${resWord}`
      }
    })
    newString = res
  }
  return {
    name: 'Test query',
    description: '',
    options: {
      saveQuery: false,
      accumulate: true,
      aggregate: newString
    },
    filters: filter,
    limit: 10
  }
}
