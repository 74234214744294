import { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { axios } from 'utils/axios'
import { TFocusGroup } from 'services/costhistory.interface'
import { useQuery } from 'react-query'
import { FetchStatus } from 'components'
import useProject from 'hooks/useProject'
import { query } from '../project-widget/widgetHelpFunctions'
import { Draggable } from './Draggable'

export interface RegionPercentageProps {
  id: string,
  key: string
  deleteCallback: (id: string) => void;
}
export function RegionPercentage({ id, key, deleteCallback }: RegionPercentageProps) {
  const {
    fetchProjectsStatus,
  } = useProject()
  const [data, setData] = useState<TFocusGroup[]>([])
  const [dataProcessed, setDataProcessed] =
    useState<boolean>(false)
  const [query, setQuery] = useState<query>(
    {
      name: uuid(),
      description: '',
      options: {
        saveQuery: false,
        accumulate: false,
        aggregate: 'region',
        step: '1d',
        window: 'lastmonth'
      },
      filters: [
      ],
      limit: 31
    }
  )
  const changeQueryId = () => {
    setQuery(
      {
        name: uuid(),
        description: '',
        options: {
          saveQuery: false,
          accumulate: false,
          aggregate: 'region',
          step: '1d',
          window: 'lastmonth'
        },
        filters: [
        ],
        limit: 31
      }
    )
  }
  const { error } = useQuery({
    queryKey: [`query?${JSON.stringify(query)}`],
    queryFn: async () => {
      try {
        if (!dataProcessed) {
          await axios
            .post('/query', query)
            .then((res) => {
              setData(res.data.data)
              setDataProcessed(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                setData([])
                setDataProcessed(true)
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  // function getMaxChild(el: newTFocus) {
  //   const childrenNames = el.resources
  //   const bestChild = childrenNames.reduce((prev: TFocus, cur: TFocus) => {
  //     return prev.monthlyCost > cur.monthlyCost ? prev : cur
  //   })
  //   return { label: bestChild.serviceName, obj: bestChild }
  // }

  function getPercentages(): { label: string, percentage: number }[] {
    if (data.length !== 0) {
      // const dataToWorkWith = data
      let totalCost = 0
      const results: { label: string, monthlyCost: number }[] = []
      data.forEach((el) => {
        const name = Object.getOwnPropertyNames(el)[0]
        const { totalEffectiveCost } = el[name]
        const cost = Number(totalEffectiveCost)
        const splitted = name.split('-')
        const newName = name === '' ? 'Unknown' : `${splitted[0]}-${splitted[1]}`
        const index = results.findIndex((el) => el.label === newName)
        totalCost += cost
        if (index !== -1) {
          results[index].monthlyCost += cost
        } else {
          results.push({ label: newName, monthlyCost: cost })
        }
      })
      const precentages: { label: string, percentage: number }[] = results.map((el) => {
        return { label: el.label, percentage: ((el.monthlyCost / totalCost) * 100) }
      })
      return precentages
    }
    return [{ label: '', percentage: 0 }]
  }

  if (error) return <div>An error has occurred</div>
  return (
    <div id={id} key={key} className="xl:w-1/5 m-2">
      <Draggable id={id} key={key} deleteCallback={deleteCallback}>
        <div key={key}>
          <FetchStatus status={fetchProjectsStatus} retry={changeQueryId}>
            <div>
              <span className="font-semibold text-gray-600">Division of regions spend in:</span>
              <div>
                {
                  getPercentages().map((el) => {
                    return (
                      <div>
                        <span className="font-semibold text-gray-500">{el.label}:</span><span> {el.percentage.toFixed(2)}%</span>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </FetchStatus>
        </div>
      </Draggable>
    </div>
  )
}
