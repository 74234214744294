import { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { axios } from 'utils/axios'
import { TFocusGroup } from 'services/costhistory.interface'
import { useQuery } from 'react-query'
import { FetchStatus } from 'components'
import useProject from 'hooks/useProject'
import { TCurrency } from 'services/cost.interface'
import { query } from '../project-widget/widgetHelpFunctions'
import { Draggable } from './Draggable'

export interface summaryBarProps {
  id: string,
  title: string,
  key: string,
  window: string,
  currency: TCurrency,
  deleteCallback: (id: string) => void
}
export function PreviousMonthSpend({
  id,
  title,
  key,
  window,
  currency,
  deleteCallback
}: summaryBarProps) {
  const {
    fetchProjectsStatus,
    // projectList,
    // fetchProjects
  } = useProject()
  // const id = uuid()
  const [data, setData] = useState<TFocusGroup[]>([])
  const [dataProcessed, setDataProcessed] =
    useState<boolean>(false)
  const [query, setQuery] = useState<query>(
    {
      name: uuid(),
      description: '',
      options: {
        saveQuery: false,
        accumulate: false,
        aggregate: 'provider',
        step: '1d',
        window
      },
      filters: [
      ]
    }
  )
  const changeQueryId = () => {
    setQuery(
      {
        name: uuid(),
        description: '',
        options: {
          saveQuery: false,
          accumulate: false,
          aggregate: 'provider',
          step: '1d',
          window
        },
        filters: [
        ]
      }
    )
  }
  const { error } = useQuery({
    queryKey: [`query?${JSON.stringify(query)}`],
    queryFn: async () => {
      try {
        if (!dataProcessed) {
          await axios
            .post('/query', query)
            .then((res) => {
              setData(res.data.data)
              setDataProcessed(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                setData([])
                setDataProcessed(true)
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })
  function getTotalCost() {
    if (data.length !== 0) {
      const dataToWorkWith = data[0]
      const objName = Object.getOwnPropertyNames(dataToWorkWith)
      if (objName.length > 0) {
        const monthlyCosts = objName.map((el) => {
          const theNamesData = dataToWorkWith[el]
          return Number(theNamesData.totalEffectiveCost)
        })
        return Number(monthlyCosts.reduce((prev, cur) => {
          return prev + cur
        }, 0).toFixed(2))
      } return 0
    } return 0
  }

  if (error) return <div>An error has occurred</div>
  return (
    <div id={id} key={key} className="xl:w-1/5 m-2">
      <Draggable id={id} key={key} deleteCallback={deleteCallback}>
        <div key={key}>
          <FetchStatus status={fetchProjectsStatus} retry={changeQueryId}>
            <div>
              <span className="font-semibold text-gray-600">{title}:</span>
              <div>
                {currency.symbol}
                {
                  getTotalCost()
                }
              </div>
            </div>
          </FetchStatus>
        </div>
      </Draggable>
    </div>
  )
}
