// import { fetchJsonData } from './constants'

import { useEffect, useState } from 'react';
import { ButtonIcon, GraphCardContent, Table, TTableColumn } from 'components';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa6';
import data from './demoTableData.json'
import { addId, costs, getAllResourceNames, jsonData, parseCosts, RESOURCE_EXPLORATION_COLUMNS, snakeToNormalCase } from './constants';
import { GroupAndFilterObject } from './GroupAndFilterObject';
import { LabelRuleCreator } from './LabelRuleCreator';
// import { MakeTable } from './MakeTable';

export function ResourceExplorer() {
  const [filters, setFilters] = useState<{ column: string, value: string }[]>([])
  const [showFilter, setShowFilter] = useState<boolean>(true)
  const [groups, setGroups] = useState<string[]>([])
  const [showGroups, setShowGroups] = useState<boolean>(true)
  const [opened, setOpened] = useState<string[]>([])
  const [idList, setIdList] = useState<string[]>([])
  const [text, setText] = useState<string>('')
  const [filterColumn, setFilterColumn] = useState<string>('')
  const [filterValue, setFilterValue] = useState<string>('')
  const [search, setSearch] = useState<string>('')
  const [tableData, setTableData] = useState<jsonData[]>([])
  const [slideCtr, setSlideCtr] = useState<number>(0)
  useEffect(() => {
    if (parseCosts().length !== 0 && tableData.length === 0) {
      setTableData(
        addId(parseCosts().sort((a, b) => a.service_name.localeCompare(b.service_name)))
      )
    }
  }, [data])
  const [show, setShow] = useState<string>('')
  const [labelRuleList, setLabelRuleList] = useState<{ columnName: string, value: string }[]>([])
  const [labelEffectList, setLabelEffectList] =
    useState<{ effectName: string, value: string }[]>([])

  function sortColumns() {
    let res: TTableColumn[] = []
    let dataWip = RESOURCE_EXPLORATION_COLUMNS
    groups.forEach((el) => {
      const col = RESOURCE_EXPLORATION_COLUMNS.find(
        (column) => column.name === snakeToNormalCase(el)
      )
      if (col) {
        dataWip = dataWip.filter((column) => column.name !== snakeToNormalCase(el))
        res.push(col)
      }
    })
    res = res.concat(dataWip)
    return res
  }

  function filterData(data: jsonData[]) {
    if (filters.length === 0) return data
    const parsedFilters = filters.map((filter) => {
      const str = filter.column
      const splitted = str.split(' ')
      let res = ''
      splitted.forEach((split) => {
        if (res === '') {
          res = split.toLowerCase()
        } else {
          res = `${res}_${split.toLowerCase()}`
        }
      })
      return { column: res, value: filter.value }
    })
    const res: jsonData[] = []
    data.forEach((el) => {
      let allowed = false
      parsedFilters.forEach((filter) => {
        if (el[filter.column].toString().includes(filter.value)) allowed = true
      })
      if (allowed) res.push(el)
    })
    return res
  }

  function searchRow(data: jsonData[]) {
    const res: jsonData[] = []
    data.forEach((el) => {
      let allowed = false
      const names = Object.getOwnPropertyNames(el)
      names.forEach((name) => {
        if (el[name].toString().includes(search)) {
          allowed = true
        }
      })
      if (allowed) res.push(el)
    })
    return res
  }

  function displayGroup(
    groups: string[],
    data: jsonData[],
    previousName: string
  ): { res: jsonData[], ids: string[] } {
    let res: jsonData[] = []
    let ids: string[] = []
    const resourceNames = getAllResourceNames(groups[0])
    if (groups.length !== 0) {
      resourceNames.forEach((name) => {
        const headerObject: jsonData = {}
        let tempRes: jsonData[] = []
        let tempIds: string[] = []
        const index = opened.findIndex((open) => `${previousName}${name}` === open)
        // res.push({ [groups[0]]: <div className="flex">{name}{icon}</div>, groupTag: 'group' })
        if (index !== -1) {
          if (groups.length === 1) {
            if (search === '') tempRes = tempRes.concat(data.filter((el) => el[groups[0]] === name))
            else tempRes = tempRes.concat(searchRow(data.filter((el) => el[groups[0]] === name)))
          } else {
            const recRes =
              displayGroup(groups.slice(1), data.filter((el) => el[groups[0]] === name), `${previousName}${name}`)
            tempRes = tempRes.concat(
              recRes.res
            )
            tempIds = tempIds.concat(
              recRes.ids
            )
          }
        }
        const properties = Object.getOwnPropertyNames(data[0])
        data.filter((el) => el[groups[0]] === name).forEach((el) => {
          properties.forEach((aNestedName) => {
            if (Number(el[aNestedName])
              && !Number(headerObject[aNestedName])) headerObject[aNestedName] = 0
            if (Number(el[aNestedName])) headerObject[aNestedName] += Number(el[aNestedName])
          })
        })
        // if (tempRes.length !== 0 || search === '') {
        const id = `${previousName}${groups[0]}${name}`
        const icon = index === -1 ? (
          <ButtonIcon
            onClick={() => {
              setOpened(opened.concat(`${previousName}${name}`))
            }}
          >
            <IoIosArrowForward color="#668957" />
          </ButtonIcon>
        ) : (
          <ButtonIcon
            onClick={() => {
              const wip = opened.filter((open) => !open.includes(`${previousName}${name}`))
              setOpened(wip)
            }}
          >
            <IoIosArrowDown color={(tempRes.length !== 0 || groups.length !== 1) ? '#668957' : 'red'} />
          </ButtonIcon>
        )
        headerObject[groups[0]] = <div className="flex">{name}{icon}</div>
        headerObject.groupTag = 'group'
        headerObject.id = id
        const header: jsonData[] = [headerObject]
        tempRes = header.concat(tempRes)
        tempIds = [id].concat(tempIds)
        res = res.concat(tempRes)
        ids = ids.concat(tempIds)
      })
    } else {
      res = searchRow(data)
    }
    const returnValue = { res, ids }
    return returnValue
  }

  function makeCellsClickable(data: jsonData[]) {
    if (data.length === 0) return []
    return data.map((el) => {
      const res: jsonData = {}
      res.id = el.id
      const names = Object.getOwnPropertyNames(el)
      names.forEach((name) => {
        if (name !== 'id' && name !== 'groupTag') {
          res[name] = (
            <div
              className="w-full h-fit hover:cursor-pointer"
              onClick={() => {
                if (el.groupTag !== 'group' && show !== `${el.id}${name}`) {
                  setShow(`${el.id}${name}`)
                } else if (show === `${el.id}${name}`) {
                  setShow('')
                }
              }}
            >
              <div className="relative">
                <div
                  data-tour="addWidgetButtonPopUp"
                  className={
                    `dropdown-body w-48 absolute border-2 border-gray-200 bg-icon-color rounded ${show === `${el.id}${name}` && 'dropdown-open'} mt-10`
                  }
                  style={{ zIndex: 999 }}
                >
                  <div
                    className="border-b border-gray-100 hover:bg-gray-50 py-2 px-2"
                    onClick={() => {
                      const index = labelRuleList.findIndex((rule) => {
                        return rule.columnName === name && rule.value === el[name]
                      })
                      if (index === -1) {
                        setLabelRuleList(
                          labelRuleList.concat({ columnName: name, value: el[name] })
                        )
                      }
                    }}
                  >
                    Add as label rule
                  </div>
                  <div
                    className="border-b border-gray-100 hover:bg-gray-50 py-2 px-2"
                    onClick={() => {
                      setLabelEffectList(labelEffectList.concat({ effectName: `Effect${labelEffectList.length + 1}`, value: el[name] }))
                    }}
                  >
                    Add as label effect
                  </div>
                  <div
                    className="border-b border-gray-100 hover:bg-gray-50 py-2 px-2"
                    onClick={() => {
                      setShow('')
                    }}
                  >
                    cancel
                  </div>
                </div>
              </div>
              {costs.findIndex((cost) => cost === name) !== -1 ? '$' : ''}{el[name]}
            </div>
          )
        }
      })
      return res
    })
  }

  useEffect(() => {
    setIdList([])
    const parsedGroups = groups.map((group) => {
      const str = group
      const splitted = str.split(' ')
      let res = ''
      splitted.forEach((split) => {
        if (res === '') {
          res = split.toLowerCase()
        } else {
          res = `${res}_${split.toLowerCase()}`
        }
      })
      return res
    })
    const grouped = displayGroup(parsedGroups, filterData(parseCosts().slice((slideCtr * 10), ((slideCtr + 1) * 10))), '')
    setIdList(grouped.ids)
    if (grouped.res.length !== 0) setTableData(grouped.res)
    else setTableData([{}])
  }, [groups, opened, search, filters, slideCtr])

  return (
    <div className="w-7/8 m-2">
      <div className="flex flex-row w-4/6">
        <div className="flex flex-row w-fit border border-gray-500 rounded-lg bg-white shadow-2xl my-3">
          <span className="border-r border-gray-500 rounded-l-lg p-1 bg-gray-300 w-20">Search:</span>
          <input
            className="h-max w-64 p-1 rounded-r-lg"
            placeholder="Type a value to search here."
            type="text"
            id="myText"
            value={search}
            onChange={(event) => {
              setOpened([])
              setSearch(event.target.value)
            }}
          />
        </div>
        <div className="w-full relative">
          <div className="flex h-full absolute right-0 flex h-fit">
            <ButtonIcon onClick={() => {
              if (slideCtr !== 0) {
                setSlideCtr(slideCtr - 1)
              }
            }}
            >
              <FaCaretLeft size={20} />
            </ButtonIcon>
            <div className="flex w-6 justify-center">
              <span className="text-sm font-semibold text-gray-600 content-center">{slideCtr + 1}/{Math.ceil(parseCosts().length / 10)}</span>
            </div>
            <ButtonIcon onClick={() => {
              if (((slideCtr + 2) * 10) - parseCosts().length < 10) {
                setSlideCtr(slideCtr + 1)
              }
            }}
            >
              <FaCaretRight size={20} />
            </ButtonIcon>
          </div>
        </div>
      </div>
      <div className="flex w-full h-fit justify-between">
        <div className="flex-auto w-4/6">
          <div className="border-2 border-[#668957] rounded-lg bg-white shadow-2xl">
            <div className="overflow-x h-[730px] overflow-scroll overflowX-hidden py-3">
              {tableData.length !== 0 && (
                <GraphCardContent minHeight={250}>
                  <Table
                    columns={sortColumns()}
                    data={makeCellsClickable(tableData)}
                    selectedRowIdList={idList}
                  />
                </GraphCardContent>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-2/6 ml-2">
          <div className="w-full border-2 border-[#668957] rounded-lg bg-white shadow-2xl mb-2">
            <div className="p-2">
              <div className="flex flex-row h-fit border border-gray-500 rounded-lg bg-white">
                <input
                  className="rounded-l-lg w-4/6"
                  placeholder="Type the name of a column here."
                  type="text"
                  id="myText"
                  value={text}
                  onChange={(event) => { setText(event.target.value) }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' && getAllResourceNames(text).length !== 0 && text.length !== 0) {
                      setGroups(groups.concat(text))
                      setText('')
                    }
                  }}
                />
                <button
                  type="button"
                  className="h-full w-2/6 border-l bg-[#668957] hover:bg-[#93ac89] border-[#668957] rounded-r-md text-white"
                  onClick={() => {
                    if (getAllResourceNames(text).length !== 0 && text.length !== 0) {
                      setGroups(groups.concat(text))
                      setText('')
                    }
                  }}
                >
                  Add group
                </button>
              </div>
            </div>
            <ButtonIcon
              onClick={() => {
                setShowGroups(!showGroups)
              }}
            >
              {showGroups ? <IoIosArrowDown /> : <IoIosArrowForward />}
            </ButtonIcon>
            <div>
              {showGroups && (
                <div className="m-1">
                  <div className="w-full grid gap-1 grid-cols-3 grid-rows-5 w-fit bg-white">
                    {groups.map((el) => {
                      return (
                        <GroupAndFilterObject
                          text={el}
                          deleteCallback={() => {
                            setGroups(groups.filter((group) => group !== el))
                          }}
                        />
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="w-full border-2 border-[#668957] rounded-lg bg-white shadow-2xl mt-2">
            <div className="p-2">
              <div className="flex flex-row h-fit border border-gray-500 rounded-lg bg-white">
                <input
                  className="rounded-l-lg border-r border-gray-500 w-2/6"
                  placeholder="Column name"
                  type="text"
                  id="myText"
                  value={filterColumn}
                  onChange={(event) => { setFilterColumn(event.target.value) }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' && getAllResourceNames(filterValue).length && getAllResourceNames(filterColumn).length !== 0 && filterValue.length !== 0 && filterColumn.length !== 0) {
                      setFilters(filters.concat({ column: filterColumn, value: filterValue }))
                      setFilterColumn('')
                      setFilterValue('')
                    }
                  }}
                />
                <span className="w-1/6 text-center bg-gray-300 border-r border-gray-500 p-1">Equal to </span>
                <input
                  className="w-2/6"
                  placeholder="Column value"
                  type="text"
                  id="myText"
                  value={filterValue}
                  onChange={(event) => { setFilterValue(event.target.value) }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' && getAllResourceNames(filterValue).length !== 0 && getAllResourceNames(filterColumn).length !== 0 && filterValue.length !== 0 && filterColumn.length !== 0) {
                      setFilters(filters.concat({ column: filterColumn, value: filterValue }))
                      setFilterColumn('')
                      setFilterValue('')
                    }
                  }}
                />
                <button
                  type="button"
                  className="h-full border-l bg-[#668957] hover:bg-[#93ac89] border-[#668957] rounded-r-md text-white w-1/6 p-1"
                  onClick={() => {
                    if (getAllResourceNames(filterValue).length !== 0
                      && getAllResourceNames(filterColumn).length !== 0
                      && filterValue.length !== 0 && filterColumn.length !== 0) {
                      setFilters(filters.concat({ column: filterColumn, value: filterValue }))
                      setFilterColumn('')
                      setFilterValue('')
                    }
                  }}
                >
                  Add filter
                </button>
              </div>
            </div>
            <ButtonIcon
              onClick={() => {
                setShowFilter(!showFilter)
              }}
            >
              {showFilter ? <IoIosArrowDown /> : <IoIosArrowForward />}
            </ButtonIcon>
            <div>
              {showFilter && (
                <div className="m-1">
                  <div className="w-full grid gap-1 grid-cols-2 grid-rows-10">
                    {filters.map((el) => {
                      return (
                        <GroupAndFilterObject
                          text={`${el.column} = ${el.value}`}
                          deleteCallback={() => {
                            setFilters(filters.filter((group) => group !== el))
                          }}
                        />
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-full border-2 border-[#668957] rounded-lg bg-white shadow-2xl mt-2 p-1">
            <LabelRuleCreator
              labelRuleList={labelRuleList}
              setLabelRuleList={setLabelRuleList}
              labelEffectList={labelEffectList}
              setLabelEffectList={setLabelEffectList}
            />
          </div>
        </div>
      </div>
      {/* <MakeTable /> */}
    </div>
  )
}
