import { MdDragIndicator } from 'react-icons/md';
import { ButtonIcon } from 'components';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useState } from 'react';
import { SlOptionsVertical } from 'react-icons/sl';
import { DraggableData } from './propsInterface';

export function Draggable(props: DraggableData) {
  const { id, children, deleteCallback } = props;
  const [show, setShow] = useState<boolean>(false)
  const [deletionConfirm, setDeletionConfirm] = useState<boolean>(false)
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id
  })
  const style = {
    backgroundColor: 'white',
    display: 'inline-flex',
    transform: CSS.Transform.toString(transform),
    transition

  };
  // className=" w-64 h-36 p-2 border-2 border-[#668957] rounded-lg bg-white shadow-2xl m-2"
  return (
    <div ref={setNodeRef} style={style} className=" w-full h-full p-3 border-2 border-[#668957] rounded-lg bg-white shadow-2xl">
      <div style={{ display: 'inline-flex', width: '100%', alignItems: 'center' }}>
        <ButtonIcon {...listeners} {...attributes} style={{ width: '30px' }}>
          <MdDragIndicator size={20} />
        </ButtonIcon>
        {children}
      </div>
      <button
        type="button"
        data-tour={`theOptionsButtonFor${id}`}
        className="text-sb-button-color right-0 top-0"
        style={{ paddingTop: '2px' }}
      >
        <SlOptionsVertical
          size={15}
          onClick={() => {
            setShow(!show)
          }}
        />
        <div className="relative">
          <div
            key={`dropDownMenuOptions${id}`}
            className={`dropdown-body w-48 absolute border-2 border-gray-200 bg-icon-color rounded ${show && 'dropdown-open'} top-7 right-0`}
            style={{ zIndex: 999 }}
            onClick={() => {
              setDeletionConfirm(true)
              setShow(false)
            }}
          >
            Delete widget
          </div>
          <div
            className={`dropdown-body w-fit absolute border-2 border-gray-200 bg-icon-color rounded ${deletionConfirm && 'dropdown-open flex'} top-7 right-0`}
          >
            <div>
              <button
                type="button"
                className="bg-white hover:bg-gray-600 text-black hover:text-white border-2 rounded-lg border-black w-16"
                onClick={() => {
                  setDeletionConfirm(false)
                  setShow(false)
                }}
              >
                Cancel
              </button>
            </div>
            <div className="pl-3">
              <button
                type="button"
                className="bg-[#BB0A1E] hover:bg-[#c83a4a] text-white border-2 rounded-lg border-[#BB0A1E] w-16"
                onClick={() => {
                  setDeletionConfirm(false)
                  deleteCallback(id)
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </button>
    </div>
  );
}
