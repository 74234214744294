import { ButtonIcon } from 'components';
import { IoClose } from 'react-icons/io5';

interface GroupAndFilterObjectProps {
  text: string,
  deleteCallback: () => void
}

export function GroupAndFilterObject({ text, deleteCallback }: GroupAndFilterObjectProps) {
  return (
    <div className="flex flex-row w-full border border-gray-300 rounded-lg bg-white shadow-2xl m-1">
      <span className="w-11/12">{text}</span>

      <div className="relative w-1/12">
        <ButtonIcon className="absolute right-1" onClick={deleteCallback}>
          <IoClose />
        </ButtonIcon>
      </div>
    </div>
  )
}
