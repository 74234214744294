import { ReactElement, useCallback, useEffect, useState } from 'react'
import { AiFillHome } from 'react-icons/ai'
import { FaClipboardList } from 'react-icons/fa'
// import { GrResources } from "react-icons/gr";
import { RiLogoutCircleRLine } from 'react-icons/ri'
import { MdOutlinePriceChange, MdOutlineTableChart } from 'react-icons/md'
// import { ReactComponent as AiFillHome } from 'assets/cloud-vue/icons/home.svg'
// import { ReactComponent as GoProject } from 'assets/cloud-vue/icons/project.svg'
// import { ReactComponent as FaClipboardList } from 'assets/cloud-vue/icons/reports.svg'
// import { ReactComponent as MdOutlinePriceChange } from 'assets/cloud-vue/icons/cost.svg'
// AiFillHome
import { Link, useLocation } from 'react-router-dom'
import logoImg from 'assets/cloud-vue/CloudVue-logo-landscape-v2.svg'
import logoImgSmall from 'assets/cloud-vue/CloudVue-logo-small.svg'
import './index.scss'

import useAuthentication from 'hooks/useAuthentication'

interface childRoute {
  name: string
  path: string
  icon?: ReactElement
}
interface sideBarProps {
  sidebarStatus: boolean
}
interface route {
  name: string
  path: string
  icon?: ReactElement
  dropdownIcon?: ReactElement
  children?: childRoute[]
}

const routes: route[] = [
  {
    name: 'Overview',
    path: '/',
    icon: <AiFillHome size={22} className="icon" />
  },
  /* {
    name: 'Reports',
    path: '/reports',
    icon: <FaClipboardList size={22} className="icon" />
  }, */
  {
    name: 'Reports',
    path: '/advanced-reporting',
    icon: <FaClipboardList size={22} className="icon" />
  },
  /* {
    name: 'External costs',
    path: '/external-costs',
    icon: <MdOutlinePriceChange size={22} className="icon" />
  }, */
  {
    name: 'Label Rules',
    path: '/label-rules',
    icon: <MdOutlinePriceChange size={22} className="icon" />
  },
  {
    name: 'Resource Explorer',
    path: '/Resource-explorer',
    icon: <MdOutlineTableChart size={22} className="icon" />
  },
]

function Sidebar({ sidebarStatus }: sideBarProps) {
  const { pathname } = useLocation()
  const { onLogout } = useAuthentication()

  const [activeMenu, setActiveMenu] = useState<route | undefined>(undefined)

  const findInSubs = (eventKey: string) => {
    const routesHaveChildren = routes.filter((item) => item.children !== undefined)
    let activeChildMenu: route | undefined
    for (let index = 0; index < routesHaveChildren.length; index += 1) {
      const selectedChildMenu = routesHaveChildren[index].children?.find(
        (item) => item.path === eventKey
      )
      if (selectedChildMenu) {
        activeChildMenu = selectedChildMenu
        break
      }
    }
    return activeChildMenu
  }

  const getActiveMenu = useCallback((path: string) => {
    let activeMenuItem: route | undefined = routes.find(
      (menu) => {
        if (path === '/' || menu.path === '/') {
          return path === menu.path
        }
        return path.startsWith(menu.path)
      }
    )
    if (!activeMenuItem) {
      activeMenuItem = findInSubs(path)
    }
    setActiveMenu(activeMenuItem)
  }, [])

  const detectMenu = (activeMenu: route, isChild?: boolean): void => {
    if (isChild) {
      getActiveMenu(activeMenu.path)
    } else {
      setActiveMenu(activeMenu)
    }
  }

  useEffect(() => {
    getActiveMenu(pathname)
  }, [getActiveMenu, pathname])

  return (
    <div className={`flex flex-col justify-between h-full bg-sb-color custom-sidebar ${!sidebarStatus ? 'w-20' : 'w-64'}`}>
      <div>
        <div className="flex justify-center items-center pt-3">
          <img src={!sidebarStatus ? logoImgSmall : logoImg} className={`${!sidebarStatus ? 'w-12' : 'w-60'}`} alt="logo" />
        </div>
        <ul className="mt-3 px-3">
          {routes?.map(
            (menu): ReactElement => (
              <Link
                key={menu.path}
                to={menu.path}
                onClick={() => detectMenu(menu)}
                className="group relative"
              >
                <li
                  className={`sidebar_nav rounded-md flex items-center cursor-pointer bg-sb-button-color text-sb-text-color px-4 py-3 hover:bg-sb-button-color-active hover:text-sb-text-color-active mb-1 ${activeMenu === menu && 'bg-sb-button-color-active text-sb-text-color-active'
                  }`}
                >
                  <div
                    className={`${sidebarStatus ? 'pr-4' : ''} hover:text-sb-text-color-active text-sb-text-color ${activeMenu === menu && 'active-icon'
                    }`}
                  >
                    {menu.icon}
                  </div>
                  {sidebarStatus && menu.name}
                </li>
                {!sidebarStatus && <span className="rounded pointer-events-none absolute top-1/2 transform -translate-y-2/4 px-3 left-16 w-max opacity-0 transition-opacity group-hover:opacity-100 z-50 bg-sb-button-color text-white">{menu.name}</span>}
              </Link>
            )
          )}
          {/* <div className="mt-20">
            <Link
              to="/configuration"
              className={`group relative sidebar_nav rounded-md flex i
              tems-center cursor-pointer bg-sb-button-color text-sb-text-col
              or px-4 py-3 hover:bg-sb-button-colo
              r-active hover:text-text-sb-text-color-active mb-1 ${pat
              hname.startsWith('/configuration') && 'bg-sb-button-color-
              active text-sb-text-color-active'
              }`}
            >
              <div
                className={`pr-4 text-sb-text-color ${pathname.startsWith('/configuration') ? 'ac
                tive-icon' : ''
                }`}
              >
                <AiFillSetting size={22} className="icon" />
              </div>
              {sidebarStatus && (
                <div className="flex justify-between items-center w-full">
                  Configuration
                </div>
              )}
              {!sidebarStatus && <span className="rounded pointer-events-
              none absolute top-1/2 transform -translate-y-2/4 px-3 left-16 w-max opacity
              -0 transition-opacity group-hover:opacity-100 z-50 bg-sb-button-color text-white">
              Configuration</span>}
            </Link>
          </div> */}
        </ul>
      </div>
      <div className="my-3 px-3 phone:hidden">
        <div
          className="flex items-center hover:bg-sb-button-color-active sidebar_nav rounded-md px-4 py-3 cursor-pointer text-sb-text-color"
          onClick={onLogout}
        >
          <div className="pr-4 text-sb-text-color">
            <RiLogoutCircleRLine size={22} />
          </div>
          {sidebarStatus && (
            <div className="flex justify-between items-center w-full">
              Logout
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Sidebar
