import { IoCloseOutline } from 'react-icons/io5'
import { ButtonIcon } from 'components'
import { snakeToNormalCase } from './constants'

interface LRComponentProps {
  columnName: string,
  value: string,
  deleteCallback: () => void
}
export function LRComponent({ columnName, value, deleteCallback }: LRComponentProps) {
  return (
    <div className="flex border border-gray-500 rounded-lg m-1">
      <div className="w-2/5 border-r border-gray-500 p-1">
        {snakeToNormalCase(columnName)}
      </div>
      <div className="p-1 bg-gray-300">
        Equal to
      </div>
      <div className="w-2/5 border-l border-gray-500 p-1">
        {value}
      </div>
      <ButtonIcon onClick={() => { deleteCallback() }}>
        <IoCloseOutline />
      </ButtonIcon>

    </div>
  )
}
