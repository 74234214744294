import { TTableColumn } from 'components';
import { formatNilValue } from 'utils';
import { v4 as uuid } from 'uuid'
// import { Box, Stack } from '@mantine/core';
// import { type MRT_ColumnDef } from 'mantine-react-table';
// import { useMemo } from 'react';
import data from './demoTableData.json'

// import { Link } from 'react-router-dom';
// import { formatNilValue, formatCost } from 'utils';
export type jsonData = {
  [index: string]: any
}
const numbers = ['daily_cost', 'weekly_cost', 'monthly_cost', 'effective_cost', 'list_cost', 'pricing_quantity', 'usage_quantity', 'billed_cost', 'list_unit_price']
export const costs = ['daily_cost', 'weekly_cost', 'monthly_cost', 'effective_cost', 'list_cost', 'billed_cost', 'list_unit_price']
export function parseCosts() {
  return data.resource.map((el: jsonData) => {
    numbers.forEach((number) => {
      const parsed = Number(el[number as keyof typeof data.resource[0]]).toFixed(2)
      el[number] = parsed
    })
    return el
  })
}
export function getAllResourceNames(property: string) {
  const res: string[] = []
  data.resource.forEach((el: jsonData) => {
    const field = property as keyof typeof el
    if (res.findIndex((str) => str === el[field]) === -1) res.push(el[field])
  })
  return res
}
export function addId(data: jsonData[]) {
  return data.map((el) => {
    el.id = uuid()
    return el
  })
}
export function snakeToNormalCase(str: string) {
  const splitted = str.split('_')
  let res = ''
  splitted.forEach((el) => {
    if (res === '') {
      res = el
    } else {
      res = `${res} ${el}`
    }
  })
  return res
}
export function parseJsonData() {
  const anElement = data.resource[0]
  let names = Object.getOwnPropertyNames(anElement).filter((el) => el !== 'updated_flag' && el !== 'data_date' && numbers.findIndex((num) => num === el) === -1)
  names = names.concat(numbers)
  let res: TTableColumn[] = []
  res = names.map((name) => {
    return ({
      name: snakeToNormalCase(name),
      field: name,
      width: '250px',
      format: formatNilValue,
      sortable: false,
      filterable: false
    })
  })
  return res
}

export type resourceExplorationCols = {
  provider: string,
  serviceName: string,
  // daily_cost: string,
  // weekly_cost: string,
  // monthly_cost: string,
  // pricing_unit: string,
  // billing_currency: string,
  // charge_description: string,
  // invoice_issuer: string,
  // charge_frequency: string,
  // service_category: string,
};

export function parseJsonDataToMantineReactCols() {
  // const anElement = data.resource[0]
  const names = ['daily_cost', 'weekly_cost', 'monthly_cost', 'pricing_unit', 'billing_currency', 'charge_description', 'invoice_issuer', 'charge_frequency', 'provider', 'service_category', 'service_name', 'pr']// Object.getOwnPropertyNames(anElement)
  let res: TTableColumn[] = []
  res = names.map((name) => {
    return ({
      name: snakeToNormalCase(name),
      field: name,
      width: '250px',
      format: formatNilValue,
      sortable: false,
      filterable: false
    })
  })
  return res
}

export const RESOURCE_EXPLORATION_COLUMNS: TTableColumn[] = parseJsonData()
